// Javascript Setup
// Turn on if you need it
import SearchBox from '../../vuejs/official/components/SearchBox.vue'

require('@rails/ujs').start()

// import "core-js/stable";
import $ from 'jquery'
import 'slick-carousel'

// CSS Setup
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'
import 'stylesheets/official/application'

// Page Specific Script
import './index'
import './photoswipe'
import './join/community'
import './join/ticket'

// Vue Setup
import Vue from 'vue/dist/vue.esm.js'
import VModal from 'vue-js-modal'
Vue.use(VModal)

import HeaderDropDown from '../../vuejs/shared/components/HeaderDropDown'

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('headerDropDown') !== null) {
    new Vue({
      el: '#headerDropDown',
      render: (h) => h(HeaderDropDown, { props: $('#headerDropDown').data() }),
    })
  }

  if (document.getElementById('top-kv-search') !== null) {
    new Vue({
      el: '#top-kv-search',
      components: { SearchBox },
    })
  }
})
