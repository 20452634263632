import PhotoSwipeLightBox from 'photoswipe/dist/photoswipe-lightbox.esm'
import PhotoSwipe from 'photoswipe/dist/photoswipe.esm'
import 'photoswipe/dist/photoswipe.css'

const pswp1 = new PhotoSwipeLightBox({
  gallery: '#pswp1',
  children: 'a.pswpImg',
  pswpModule: () => PhotoSwipe,
})

const pswp2 = new PhotoSwipeLightBox({
  gallery: '#pswp2',
  children: 'a.pswpImg',
  pswpModule: () => PhotoSwipe,
})

const pswp3 = new PhotoSwipeLightBox({
  gallery: '#pswp3',
  children: 'a.pswpImg',
  pswpModule: () => PhotoSwipe,
})

const pswp4 = new PhotoSwipeLightBox({
  gallery: '#pswp4',
  children: 'a.pswpImg',
  pswpModule: () => PhotoSwipe,
})

const pswp5 = new PhotoSwipeLightBox({
  gallery: '#pswp5',
  children: 'a.pswpImg',
  pswpModule: () => PhotoSwipe,
})

const pswp6 = new PhotoSwipeLightBox({
  gallery: '#pswp6',
  children: 'a.pswpImg',
  pswpModule: () => PhotoSwipe,
})

const pswp7 = new PhotoSwipeLightBox({
  gallery: '#pswp7',
  children: 'a.pswpImg',
  pswpModule: () => PhotoSwipe,
})

const pswp8 = new PhotoSwipeLightBox({
  gallery: '#pswp8',
  children: 'a.pswpImg',
  pswpModule: () => PhotoSwipe,
})

const pswp9 = new PhotoSwipeLightBox({
  gallery: '#pswp9',
  children: 'a.pswpImg',
  pswpModule: () => PhotoSwipe,
})

const pswp10 = new PhotoSwipeLightBox({
  gallery: '#pswp10',
  children: 'a.pswpImg',
  pswpModule: () => PhotoSwipe,
})

window.onload = () => {
  pswp1.init()
  pswp2.init()
  pswp3.init()
  pswp4.init()
  pswp5.init()
  pswp6.init()
  pswp7.init()
  pswp8.init()
  pswp9.init()
  pswp10.init()
}
