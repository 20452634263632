document.addEventListener('DOMContentLoaded', () => {
  document.getElementsByName('prefecture').forEach((select) => {
    select.addEventListener('change', (event) => {
      scrollToCheckout()
      sessionStorage.setItem('prefectureSelected', event.target.value)
      activateSubmitButton(true)
    })
  })

  let prefectureSelected = false
  if (sessionStorage.getItem('prefectureSelected')) {
    prefectureSelected = true
    const selected = sessionStorage.getItem('prefectureSelected')
    document.getElementsByName('prefecture').forEach((select) => {
      select.options[selected].selected = true
    })
  }

  activateSubmitButton(prefectureSelected)
})

function scrollToCheckout() {
  const checkoutContainer = document.getElementById('checkoutContainer')
  checkoutContainer.scrollIntoView({
    block: 'start',
    behavior: 'smooth',
  })
}

function activateSubmitButton(prefectureSelected) {
  const paymentMethodRegistered = document.getElementById('paymentMethodRegistered') !== null
  const submitButton = document.getElementById('submitCommunity')
  if (submitButton !== null) {
    if (prefectureSelected && paymentMethodRegistered) {
      submitButton.disabled = false
    } else {
      submitButton.disabled = true
    }
  }
}
