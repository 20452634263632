import $ from 'jquery'
import 'slick-carousel'

$(function () {
  $('.top-kv-slider')
    .on('init', function () {
      $('.slick-slide[data-slick-index="0"]').addClass('add-animation')
    })
    .slick({
      autoplay: true,
      fade: true,
      arrows: false,
      speed: 3000,
      autoplaySpeed: 4000,
      pauseOnFocus: false,
      pauseOnHover: false,
    })
    .on({
      beforeChange: function (event, slick, currentSlide, nextSlide) {
        $('.slick-slide', this).eq(nextSlide).addClass('add-animation')
        $('.slick-slide', this).eq(currentSlide).addClass('remove-animation')
      },
      afterChange: function () {
        $('.remove-animation', this).removeClass('remove-animation add-animation')
      },
    })

  $('.top-homesimage-slider')
    .on('init', function () {
      var $dots = $(this).find('.slick-dots li')
      if ($dots.length > 3) {
        $dots.slice(3).hide()
      }
    })
    .slick({
      autoplay: false,
      dots: true,
      arrows: true,
      swipeToSlide: true,
      lazyLoad: 'ondemand',
    })

  //トップのタブ
  $('.top-tab').on('click', function () {
    $('.top-tab, .top-tabcontent').removeClass('active')
    $(this).addClass('active')
    var index = $('.top-tab').index(this)
    $('.top-tabcontent').eq(index).addClass('active')
    $('.top-tabcontent').eq(index).find('.top-homesimage-slider').slick('setPosition').slick('resize')
    $('body,html').animate({ scrollTop: $('#top-tab-section').offset().top }, 500)
  })

  //よくある質問などのアコーディオンUI
  $('.accordion dd').hide()
  $('.accordion dt').on('click', function () {
    $('+dd', this).slideToggle(300)
  })

  //ページ内アンカーリンクスクロール
  $('a[href*="#"]').on('click', function () {
    var elmHash = $(this).attr('href')
    var pos = $(elmHash).offset().top - 100
    $('body,html').animate({ scrollTop: pos }, 500)
    return false
  })

  //家紹介カルーセル
  $('.top-homes-slide').slick({
    infinite: false,
    variableWidth: true,
    slidesToScroll: 4,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToScroll: 3,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: 'unslick',
      },
    ],
  })

  // 家画像modal
  ;(function () {
    const openbtns = document.querySelectorAll('.modal-open')
    const closebtns = document.querySelectorAll('.modal-close')
    const wrapper = document.querySelector('.modal-wrapper')
    const modalOpen = (btn) => {
      const modalId = btn.dataset.modal
      const modal = document.querySelector(`.modal[data-modal="${modalId}"]`)
      modal.insertAdjacentHTML('beforeend', '<i class="modal-bg"></i>')
      modal.classList.add('is-open')
      const top = window.scrollY
      wrapper.classList.add('is-modalopen')
      wrapper.style.top = `-${top}px`
      const modalbg = document.querySelector('.modal-bg')
      modalbg.addEventListener('click', () => {
        modalClose()
      })
    }
    const modalClose = () => {
      const modal = document.querySelector(`.modal.is-open`)
      const getY = (callback) => {
        modal.classList.remove('is-open')
        const modalbg = document.querySelector('.modal-bg')
        const top = wrapper.style.top
        const y = top.slice(1, -2)
        wrapper.style.top = ''
        wrapper.classList.remove('is-modalopen')
        modalbg.parentNode.removeChild(modalbg)
        callback(y)
      }
      getY((y) => {
        window.scrollTo(0, y)
      })
    }
    openbtns.forEach((openbtn) => {
      openbtn.addEventListener('click', (event) => {
        const btn = event.currentTarget
        modalOpen(btn)
      })
    })
    closebtns.forEach((closebtn) => {
      closebtn.addEventListener('click', () => {
        modalClose()
      })
    })
  })()
})
