<template>
  <div class="bg-white rounded-xl absolute left-4 right-4 md:static top-[190px] md:top-0 p-4 md:p-6 max-w-md">
    <button
      class="border border-bordergray rounded-lg flex items-center p-3 md:p-4 w-full"
      data-modal="modal-select-area"
      @click.prevent="$modal.show('select-area')"
    >
      <div class="flex items-center w-full">
        <p class="font-bold text-brown text-sm pr-2 whitespace-nowrap">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#AC9890"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="inline"
          >
            <circle cx="12" cy="10" r="3" />
            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
          </svg>
          エリア
        </p>
        <p class="truncate">{{ fieldLabel }}</p>
      </div>
    </button>
    <p class="font-bold mt-4 mb-2">タグで絞り込み</p>
    <div class="flex flex-wrap gap-2">
      <div
        v-for="tag in tags"
        :key="`tag-${tag.id}`"
        class="top-search-tag cursor-pointer"
        :class="{ checked: checkedTag === tag.id }"
        @click="checkedTag = checkedTag === tag.id ? null : tag.id"
      >
        {{ tag.name }}
      </div>
    </div>
    <div class="mt-4 md:mt-6">
      <button class="btn btn-orange w-full" @click="search">ADDressの家を探す</button>
    </div>
    <Teleport to="body">
      <modal
        name="select-area"
        height="100%"
        classes="modal-wrapper"
        scrollable
        style="z-index: 1050; display: flex; align-items: flex-start; justify-content: center; height: 100%"
      >
        <div class="modal-select-area md:mt-2 md:mb-2">
          <div class="modal-content relative">
            <div class="modal-scrollable-content pt-12 px-8 pb-16 md:px-12" style="height: 100%">
              <div v-for="area in areas" :key="'area-' + area.id" class="mb-8">
                <div class="mb-2">
                  <input
                    :id="'area_' + area.id"
                    type="checkbox"
                    :checked="checkedAreas.includes(area.id)"
                    @change="areaChanged($event, area)"
                  />
                  <label :for="'area_' + area.id">{{ area.name + 'エリア' }}</label>
                </div>
                <div class="flex gap-x-4 gap-y-2 flex-wrap pl-4">
                  <div v-for="pref in area.prefectures" :key="'pref-' + pref.id">
                    <input
                      :id="'pref_' + pref.id"
                      type="checkbox"
                      class="checkbox mx-2 mb-3"
                      :checked="checkedPrefs.includes(pref.id)"
                      @change="prefChanged($event, pref)"
                    />
                    <label :for="'pref_' + pref.id">{{ pref.name }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-close-wrap">
              <button class="modal-close text-gray font-bold mx-auto py-4" @click.prevent="$modal.hide('select-area')">
                <svg
                  class="inline-block"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#adadad"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
                閉じる
              </button>
            </div>
          </div>
        </div>
      </modal>
    </Teleport>
  </div>
</template>

<script>
import Teleport from 'vue2-teleport'

const AREAS = [
  {
    id: 1,
    name: '北海道',
    prefectures: [{ id: 1, name: '北海道' }],
  },
  {
    id: 2,
    name: '東北',
    prefectures: [
      { id: 2, name: '青森県' },
      { id: 3, name: '岩手県' },
      { id: 4, name: '宮城県' },
      { id: 5, name: '秋田県' },
      { id: 6, name: '山形県' },
      { id: 7, name: '福島県' },
    ],
  },
  {
    id: 3,
    name: '北関東',
    prefectures: [
      { id: 8, name: '茨城県' },
      { id: 9, name: '栃木県' },
      { id: 10, name: '群馬県' },
    ],
  },
  {
    id: 4,
    name: '首都圏',
    prefectures: [
      { id: 11, name: '埼玉県' },
      { id: 12, name: '千葉県' },
      { id: 13, name: '東京都' },
      { id: 14, name: '神奈川県' },
    ],
  },
  {
    id: 5,
    name: '甲信越',
    prefectures: [
      { id: 15, name: '新潟県' },
      { id: 16, name: '山梨県' },
      { id: 17, name: '長野県' },
    ],
  },
  {
    id: 6,
    name: '北陸',
    prefectures: [
      { id: 18, name: '富山県' },
      { id: 19, name: '石川県' },
      { id: 20, name: '福井県' },
    ],
  },
  {
    id: 7,
    name: '東海',
    prefectures: [
      { id: 21, name: '岐阜県' },
      { id: 22, name: '静岡県' },
      { id: 23, name: '愛知県' },
      { id: 24, name: '三重県' },
    ],
  },
  {
    id: 8,
    name: '関西',
    prefectures: [
      { id: 25, name: '滋賀県' },
      { id: 26, name: '京都府' },
      { id: 27, name: '大阪府' },
      { id: 28, name: '兵庫県' },
      { id: 29, name: '奈良県' },
      { id: 30, name: '和歌山県' },
    ],
  },
  {
    id: 9,
    name: '中国',
    prefectures: [
      { id: 31, name: '鳥取県' },
      { id: 32, name: '島根県' },
      { id: 33, name: '岡山県' },
      { id: 34, name: '広島県' },
      { id: 35, name: '山口県' },
    ],
  },
  {
    id: 10,
    name: '四国',
    prefectures: [
      { id: 36, name: '徳島県' },
      { id: 37, name: '香川県' },
      { id: 38, name: '愛媛県' },
      { id: 39, name: '高知県' },
    ],
  },
  {
    id: 11,
    name: '九州',
    prefectures: [
      { id: 40, name: '福岡県' },
      { id: 41, name: '佐賀県' },
      { id: 42, name: '長崎県' },
      { id: 43, name: '熊本県' },
      { id: 44, name: '大分県' },
      { id: 45, name: '宮崎県' },
      { id: 46, name: '鹿児島県' },
    ],
  },
  {
    id: 12,
    name: '沖縄',
    prefectures: [{ id: 47, name: '沖縄県' }],
  },
  {
    id: 13,
    name: '海外',
    prefectures: [{ id: 48, name: '海外' }],
  },
]
const removeItem = (array, item) => {
  return array.filter((arrItem) => {
    return arrItem !== item
  })
}
const allPrefsChecked = (areaObj, checkedPrefIds) => {
  let hasAllPrefs = true
  areaObj.prefectures.forEach((pref) => {
    if (!checkedPrefIds.includes(pref.id)) {
      hasAllPrefs = false
    }
  })
  return hasAllPrefs
}
const findArea = (prefId) => {
  let result = null
  AREAS.forEach((area) => {
    let prefIndex = area.prefectures.findIndex((pref) => {
      return pref.id === prefId
    })
    if (prefIndex >= 0) {
      result = area.id
    }
  })
  return result
}

export default {
  components: {
    Teleport,
  },
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      areas: AREAS,
      formData: {
        areas: [],
        prefs: [],
        tags: [],
      },
      checkedAreas: [],
      checkedPrefs: [],
      checkedTag: null,
    }
  },
  computed: {
    fieldLabel() {
      if (this.checkedPrefs.length < 1 && this.checkedAreas.length < 1) {
        return '指定なし'
      }
      let names = []
      if (this.checkedAreas.length > 0) {
        this.checkedAreas.forEach((savedArea) => {
          this.areas.forEach((area) => {
            if (savedArea === area.id) {
              names.push(area.name)
            }
          })
        })
      }
      if (this.checkedPrefs.length > 0) {
        this.checkedPrefs.forEach((savedPref) => {
          this.areas.forEach((area) => {
            area.prefectures.forEach((pref) => {
              if (savedPref === pref.id) {
                names.push(pref.name)
              }
            })
          })
        })
      }
      return names.join('、')
    },
  },
  methods: {
    areaChanged(event, area) {
      if (event.target.checked) {
        if (!this.checkedAreas.includes(area.id)) {
          this.checkedAreas.push(area.id)
        }
        area.prefectures.forEach((pref) => {
          if (!this.checkedPrefs.includes(pref.id)) {
            this.checkedPrefs.push(pref.id)
          }
        })
      } else {
        this.checkedAreas = removeItem(this.checkedAreas, area.id)
        area.prefectures.forEach((pref) => {
          this.checkedPrefs = removeItem(this.checkedPrefs, pref.id)
        })
      }
    },
    prefChanged(event, pref) {
      if (event.target.checked) {
        if (!this.checkedPrefs.includes(pref.id)) {
          this.checkedPrefs.push(pref.id)
        }
        this.areas.forEach((area) => {
          if (allPrefsChecked(area, this.checkedPrefs) && !this.checkedAreas.includes(area.id)) {
            this.checkedAreas.push(area.id)
          }
        })
      } else {
        this.checkedPrefs = removeItem(this.checkedPrefs, pref.id)
        this.areas.forEach((area) => {
          if (!allPrefsChecked(area, this.checkedPrefs)) {
            this.checkedAreas = removeItem(this.checkedAreas, area.id)
          }
        })
      }
    },
    search() {
      const url = new URL(window.location)
      let pathName = '/homes'
      let searchParams = {}

      // エリア
      if (this.checkedAreas.length === 1 && this.checkedPrefs.length === 1) {
        const prefId = this.checkedPrefs[0]
        const areaId = findArea(prefId)
        if (!!areaId && areaId === this.checkedAreas[0]) {
          pathName += `/areas/${this.checkedAreas[0]}/prefs/${prefId}`
        }
      } else if (this.checkedAreas.length === 1 && this.checkedPrefs.length === 0) {
        pathName += `/areas/${this.checkedAreas[0]}`
      } else if (this.checkedPrefs.length === 1 && this.checkedAreas.length === 0) {
        // find area
        const prefId = this.checkedPrefs[0]
        const areaId = findArea(prefId)
        if (areaId) {
          pathName += `/areas/${areaId}/prefs/${prefId}`
        }
      }
      if (pathName === '/homes') {
        pathName += '/search'
        if (this.checkedAreas.length > 0) {
          searchParams.areas = this.checkedAreas
        }
        if (this.checkedPrefs.length > 0) {
          searchParams.prefs = this.checkedPrefs
        }
      }
      if (this.checkedTag) {
        searchParams.tags = [this.checkedTag]
      }

      url.pathname = pathName
      url.search = ''
      for (const [key, val] of Object.entries(searchParams)) {
        if (val) {
          url.searchParams.append(`q[${key}]`, val)
        }
      }
      window.location.href = url.href
    },
  },
}
</script>

<style lang="scss">
.vm--modal {
  top: initial !important;
  left: initial !important;
  width: initial !important;
  height: initial !important;
  background-color: initial !important;
  box-shadow: initial !important;
}

.vm--block-scroll header {
  z-index: initial !important;
}

.vm--block-scroll .sticky {
  z-index: initial !important;
}

.modal-select-area {
  align-items: unset;
  overflow-y: scroll;
  @media (width <= 767px) {
    padding: 0;
  }

  .modal-content {
    height: 100vh;
    @media (width <= 767px) {
      padding: 0;
      border-radius: 0;
    }
    @media (width >= 768px) {
      width: 800px;
      padding: 0;
    }
  }

  .modal-close-wrap {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    border-top: solid 1px #e3e3e3;
    @media (width >= 768px) {
      width: 800px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}

.modal-scrollable-content {
  overflow-y: auto;
}
</style>
