document.addEventListener('DOMContentLoaded', () => {
  document.getElementsByName('plan').forEach((radio) => {
    radio.addEventListener('change', (event) => {
      scrollToCheckout()
      sessionStorage.setItem('planSelected', event.target.value)
      activateSubmitButton(true)
    })
  })

  let planSelected = false
  if (sessionStorage.getItem('planSelected')) {
    planSelected = true
    const selected = sessionStorage.getItem('planSelected')
    document.getElementsByName('plan').forEach((radio) => {
      if (radio.value === selected) {
        radio.checked = true
      }
    })
  }

  activateSubmitButton(planSelected)
})

function scrollToCheckout() {
  const checkoutContainer = document.getElementById('checkoutContainer')
  checkoutContainer.scrollIntoView({
    block: 'start',
    behavior: 'smooth',
  })
}

function activateSubmitButton(planSelected) {
  const paymentMethodRegistered = document.getElementById('paymentMethodRegistered') !== null
  const submitButton = document.getElementById('submitTicket')
  if (submitButton !== null) {
    if (planSelected && paymentMethodRegistered) {
      submitButton.disabled = false
    } else {
      submitButton.disabled = true
    }
  }
}
